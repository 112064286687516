<template>
  <div class="calaveritaNombre">
    <div class="name">{{ childName }}</div>
  </div>
</template>
<script>
export default {
  name: "CalaveraImg",

  props: ["childName"],
};
</script>
