<template>
  <div class="wrapper">
    <div class="container">
      <div class="container-in">
        <div class="row center">
          <h1>Calaverita Generator</h1>
        </div>
        <div class="row center">
          <form @submit.prevent="getInput()" class="row">
            <div class="row sm">
              <Inputfield
                phName="Nombre de la maestra"
                :autofocus="true"
                name="tName"
                type="text"
                v-model="teacherName"
              />
            </div>
            <div class="row sm">
              <Inputfield
                phName="Nombre de la escuela"
                :autofocus="true"
                name="sName"
                type="text"
                v-model="schoolName"
              />
            </div>
            <div class="row sm">
              <Inputfield
                phName="Tu nombre"
                :autofocus="true"
                name="uName"
                type="text"
                v-model="userName"
              />
            </div>
          </form>
        </div>
        <div class="row between">
          <div class="row md">
            <h2>Así queda tu calaverita</h2>
          </div>
          <div class="row sm right xcenter">
            <button @click="getInput()" class="btn__primary min">
              Cargar otra calaverita
            </button>
          </div>

          <hr />
          <div class="row center">
            <Clvdos
              :teacherName="teacherName"
              :schoolName="schoolName"
              :userName="userName"
              :showThis="number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row between ccrights__container">
      <p class="ccrights">
        Todos los derechos reservados calaveritamaker.com® 2021
      </p>
      <a
        class="ccrights__img"
        rel="license"
        href="http://creativecommons.org/licenses/by-sa/4.0/"
        ><img
          alt="Licencia Creative Commons"
          style="border-width: 0"
          src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png"
        />
        <span xmlns:dct="http://purl.org/dc/terms/" property="dct:title"
          >Calaverita Maker</span
        ></a
      >
    </div>
  </div>
</template>

<script>
import larButton from "@/components/Button";
import Clv1 from "@/components/Templates/Clv1";
import Clvdos from "@/components/Clv2";
import CalaveritaImg from "../components/CalaveraImg";
import Inputfield from "../components/InputField";

export default {
  name: "Home",

  data() {
    return {
      teacherName: "",
      schoolName: "",
      userName: "",
      number: 1,
      showModal: false,
    };
  },

  components: {
    larButton,
    Inputfield,
    CalaveritaImg,
    Clv1,
    Clvdos,
  },

  methods: {
    getInput() {
      this.min = 1;
      this.max = 8;
      this.getRandomNumber();
    },
    getRandomNumber: function () {
      this.number = this.generateNumber();
    },
    generateNumber: function () {
      return Math.floor(Math.random() * (this.max - this.min + 1) + this.min);
    },
    getPdf() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },

  computed: {
    randomCalavera() {
      const random = Math.floor(Math.random() * 6);
      return [random];
    },
  },
};
</script>
