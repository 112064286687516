<template>
  <div class="">
    <p v-show="showThis === 1" class="is-size-4 is-size-5-mobile">
      Hay noches que no se pueden olvidar como la siguiente historia que les voy
      a platicar.<br /><br />
      De la maestra <strong>{{ teacherName }}</strong> voy a hablar.<br />
      El 1ro de noviembre algo pasaba<br />
      Pues la maestra <strong>{{ teacherName }}</strong> sintió que alguien la
      observaba. <br /><br />
      Corrió a prender la luz mientras tanto preguntaba: <br />
      “Catrina, si acaso eres tú no me lleves todavía. <br />
      que a diario llevo a los niños mucha alegría” <br /><br />
      Sorprendida la flaca, contestó alzando las cejas:<br />
      “No te preocupes, yo de ti no tengo quejas.<br />
      Vine a ver si tú ofrenda haz montado,<br /><br />
      pues se que a tus muertitos les gusta el recalentado.” <br />
      Desde entonces cada año siempre puntual <br />
      la maestra
      <strong>{{ teacherName }}</strong> pone ofrenda y en Ella uno que otro
      tamal.
    </p>
    <p v-show="showThis === 2" class="is-size-4 is-size-5-mobile">
      Como siempre a su salón Maestra <strong>{{ teacherName }}</strong> con sus
      alumnos llegó. <br />
      Y después de saludarlos una historia les contó. <br /><br />
      Con fantasmas y calaveras a sus alumnos asustó...<br />
      Pues les dijo que a flojos se llevaría <br />
      Y a quien no se portara bien siempre asustaría <br /><br />
      "¿Qué te pasa <strong>{{ userName }}</strong> ?"" la maestra
      <strong>{{ teacherName }}</strong> le preguntó <br />
      Y el niño asustado a la Maestra le contestó <br />
      “Tengo miedo de lo que nos está contando <br />
      Pues a diario en la escuela mal me estoy portando,<br />
      Desde entonces la Catrina a <strong>{{ userName }}</strong> siempre estará
      observando.
    </p>
    <p v-show="showThis === 3" class="is-size-4 is-size-5-mobile">
      La maestra <strong>{{ teacherName }}</strong> al salón llegó <br />
      y ¡oh sorpresa! A todos sorprendió<br />
      dulces y dorilocos a todos les regaló<br /><br />
      Después de los regalos una historia les contó <br />
      con fantasmas y calaveras a sus alumnos espantó... <br /><br />
      ... La más chiquita de todas <strong>{{ userName }}</strong> se llamaba<br />
      que en particular fue la que mas se espantaba.
      <br /><br />
      Al salir de la escuela su mamá por
      <strong>{{ userName }}</strong> llegó,<br />
      y muy espantada a la chaparrita encontró.<br /><br />
      ¿Qué te pasa hijita? ella le preguntó <br />
      Y <strong>{{ userName }}</strong> haciendo pucheros a sú mamá contestó:
      <br />
      - la maestra nos contó que la calaca a los feitos andaba buscando <br />
      y de mi papá tengo miedo pues el de feo está exagerando...
    </p>
    <p v-show="showThis === 4" class="is-size-4 is-size-5-mobile">
      Un húmedo noviembre del año presente <br />
      la Catrina se paseaba sonriendo reluciente. <br />
      Pues venía por algún alumno desobediente <br /><br />
      <strong>{{ schoolName }}</strong> la escuela se llamaba <br />
      A donde la flaca ansiosa llegaba, <br />
      y con la maestra <strong>{{ teacherName }}</strong> ella se acercaba.
      <br /><br />
      “¡Deja a mis alumnos!” <strong>{{ teacherName }}</strong> a la Catrina le
      gritaba. <br />
      Mientras que chetos y dorilocos le arrojaba, <br />
      Felices su alumnos, de la muerte se burlaban. <br /><br />
      La Catrina no tuvo otra opción que regresarse directivo al panteón, <br />
      pues vió como <strong>{{ teacherName }}</strong> los defendía con
      devoción.
    </p>

    <p v-show="showThis === 5" class="is-size-4 is-size-5-mobile">
      Este año la Catrina anda contenta <br />
      pues se ríe del problema al que los humanos se enfrentan. <br />
      <br />
      El coronavirus a la escuela <strong>{{ schoolName }}</strong> ha
      llegado<br />
      y en especial viene por los alumnos que mal se han portado.<br />
      <br /><br />

      Y aunque la maestra <strong>{{ teacherName }}</strong> defienda a sus
      alumnos con devoción <br />
      los que no prendan la cámara se irán directito al panteón. <br />

      <br /><br />
      "Aunque uses cubrebocas o antibacterial <br />
      te llevaré hasta el panteón infernal" <br />
      Le dijo la Huesuda a <strong>{{ userName }}</strong> muy formal.<br />
      "No me importa que me ofrezcas pan de muerto",<br />
      pues todo lo que te digo es bastante cierto.
    </p>
  </div>
</template>
<script>
export default {
  name: "Clv1",

  props: ["teacherName", "schoolName", "userName", "showThis"],
};
</script>
