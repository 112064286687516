<template>
  <div class="row center">
    <p class="larDocgen__text" v-html="replacer"></p>

    <div class="row">
      <Modal :showBox="showModal" @closeModal="closeModal()">
        <GeneratedDoc :name="userName" :content="replacer" />
      </Modal>
      <div class="row right hide-on-mobile">
        <span class="btn__tertiary" @click="getPdf()">Descargar</span>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import GeneratedDoc from "@/components/DocGenerated";
import Modal from "@/components/Modal";
export default {
  name: "Clvdos",

  components: {
    GeneratedDoc,
    Modal,
  },

  props: ["teacherName", "schoolName", "userName", "showThis"],

  mounted() {
    this.showThis;
    this.teacherName, this.schoolName, this.userName;
  },

  data() {
    return {
      showModal: false,
      tname: this.teacherName,
      sName: this.schoolName,
      uName: this.userName,
    };
  },

  methods: {
    getPdf() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },

  computed: {
    replacer() {
      let random = this.showThis;
      if ([this.teacherName, this.schoolName, this.userName].includes("")) {
        this.teacherName = "Tronchatoro";
        this.schoolName = "Apachúrralos";
        this.userName = "Matilda";
      }
      switch (random) {
        case 1:
          return `Hay noches que no se pueden olvidar,<br /> como la siguiente historia que les voy
      a platicar.<br /><br />
      De la maestra <strong>${this.teacherName}</strong> voy a hablar.<br />
      El 1ro de noviembre algo pasaba<br />
      Pues la maestra <strong>${this.teacherName}</strong> sintió que alguien la
      observaba. <br /><br />
      Corrió a prender la luz mientras tanto preguntaba: <br />
      “Catrina, si acaso eres tú no me lleves todavía. <br />
      que a diario llevo a los niños mucha alegría” <br /><br />
      Sorprendida la flaca, contestó alzando las cejas:<br />
      “No te preocupes, yo de ti no tengo quejas.<br />
      Vine a ver si tú ofrenda haz montado,<br /><br />
      pues se que a tus muertitos les gusta el recalentado.” <br />
      Desde entonces cada año siempre puntual <br />
      la maestra
      <strong>${this.teacherName}</strong> pone ofrenda y en ella uno que otro
      tamal.`;
        case 2:
          return ` Como siempre a su salón Maestra <strong>${this.teacherName}</strong> con sus
      alumnos llegó. <br />
      Y después de saludarlos una historia les contó. <br /><br />
      Con fantasmas y calaveras a sus alumnos asustó...<br />
      Pues les dijo que a flojos se llevaría <br />
      Y a quien no se portara bien siempre asustaría <br /><br />
      "¿Qué te pasa <strong>${this.userName}</strong> ?" la maestra
      <strong>${this.teacherName}</strong> le preguntó <br />
      Y el niño asustado a la Maestra le contestó <br />
      “Tengo miedo de lo que nos está contando <br />
      Pues a diario en la escuela mal me estoy portando,<br />
      Desde entonces la Catrina a <strong>${this.userName}</strong> siempre estará
      observando.`;

        case 3:
          return ` La maestra <strong>${this.teacherName}</strong> al salón llegó <br />
      y ¡oh sorpresa! A todos sorprendió<br />
      dulces y dorilocos a todos les regaló<br /><br />
      Después de los regalos una historia les contó <br />
      con fantasmas y calaveras a sus alumnos espantó... <br /><br />
      ... La más chiquita de todas <strong>${this.userName}</strong> se llamaba<br />
      que en particular fue la que mas se espantaba.
      <br /><br />
      Al salir de la escuela su mamá por
      <strong>${this.userName}</strong> llegó,<br />
      y muy espantada a la chaparrita encontró.<br /><br />
      ¿Qué te pasa hijita? ella le preguntó <br />
      Y <strong>${this.userName}</strong> haciendo pucheros a sú mamá contestó:
      <br />
      - la maestra nos contó que la calaca a los feitos andaba buscando <br />
      y de mi papá tengo miedo pues el de feo está exagerando...`;

        case 4:
          return `
      Un húmedo noviembre del año presente <br />
      la Catrina se paseaba sonriendo reluciente. <br />
      Pues venía por algún alumno desobediente <br /><br />
      <strong>${this.schoolName}</strong> la escuela se llamaba <br />
      A donde la flaca ansiosa llegaba, <br />
      y con la maestra <strong>${this.teacherName}</strong> ella se acercaba.
      <br /><br />
      “¡Deja a mis alumnos!” <strong>${this.teacherName}</strong> a la Catrina le
      gritaba. <br />
      Mientras que chetos y dorilocos le arrojaba, <br />
      Felices su alumnos, de la muerte se burlaban. <br /><br />
      La Catrina no tuvo otra opción que regresarse directivo al panteón, <br />
      pues vió como <strong>${this.teacherName}</strong> los defendía con
      devoción.
      `;
        case 5:
          return `
       Este año la Catrina anda contenta <br />
      pues se ríe del problema al que los humanos se enfrentan. <br />
      <br />
      El coronavirus a la escuela <strong>${this.schoolName}</strong> ha
      llegado<br />
      y en especial viene por los alumnos que mal se han portado.<br />
      <br /><br />

      Y aunque la maestra <strong>${this.teacherName}</strong> defienda a sus
      alumnos con devoción <br />
      los que no prendan la cámara se irán directito al panteón. <br />

      <br /><br />
      "Aunque uses cubrebocas o antibacterial <br />
      te llevaré hasta el panteón infernal" <br />
      Le dijo la Huesuda a <strong>${this.userName}</strong>,  muy formal.<br />
      "No me importa que me ofrezcas pan de muerto,<br />
      pues todo lo que te digo es bastante cierto."
      `;

        case 6:
          return `
      Este año no es como cualquiera,
      <br/> 
      pues la Huesuda viene como fiera.
      <br /><br />

      A <strong>${this.userName}</strong> no se quiere llevar,
      <br/> 
      pues teme que de Covid la vaya a contagiar.
      <br />
      Dijo que viene por quienes no se han vacunado,
      <br/> 
      pues en el panteón ya tiene un espacio reservado.

      <br /><br />

      En <strong>${this.schoolName}</strong> revisará primero,
      <br/> 
      pues parece que viene en busca del "mero mero".
      <br />
      Pero antes que nada sus vacunas va a revisar,<br/> 
      pues no quiere sanos a los que tenga que alimentar.
      
      <br /><br/>      
      
      "Si tus vacunas no tienes, a ti te voy a llevar,<br/> 
      pues con sana distancia no basta (para variar).<br/> 
      Por la maestra <strong>${this.teacherName}</strong> he venido,
      <br/> 
      pues ya que ni por zoom la he conocido"<br/> 
      <br /><br />
      No bajes la guardia, en estos días,<br/> 
      pues la Calaca te vigila, como feroz arpía.
      `;

      case 7:
          return `
      La Catrina apareció en tik-tok bailando,
      <br />
      y al parecer solo andaba vacilando.
      <br />
      Entre sus seguidores andaba buscando,
      <br />
      a ver si <strong>${this.userName}</strong> la andaba "troleando".

      <br /><br />
      A partir de este año la Flaca, por los influencers vendrá,
      <br />
      pero solo a los más farsantes se llevará.
      <br /><br />

      Piensa en que quieres trabajar,<br />
      y a tu maestra <strong>${this.teacherName}</strong> debes escuchar,<br />
      pues si un influencer quieres ser,
      <br />
      Hay una tumba con tu nombre en donde vas a perecer.
        <br />  <br />

       Muy contenta la Catrina comentaba,<br />
       que a los de la escuela <strong>${this.schoolName}</strong>,<br />
       a esos sí no los tocaba.<br />
       Pues <strong>${this.teacherName}</strong> puso una ofrenda peculiar,<br />
       en donde encontró unos buenos tamales pa' degustar.
      `;

      case 8:
          return `
      Este año no es como cualquiera,
      <br/> 
      pues la Huesuda viene como fiera.
      <br /><br />

      A <strong>${this.userName}</strong> no se quiere llevar,
      <br/> 
      pues teme que de Covid se vaya a contagiar.
      <br />
      Dijo que viene por quienes no se han vacunado,
      <br/> 
      pues en el panteón ya tiene un espacio reservado.
      <br /><br />

      En <strong>${this.schoolName}</strong> revisará primero,
      <br/> 
      pues parece que viene en busca del "mero mero".
      <br />
      Pero antes que nada sus vacunas va a revisar,<br/> 
      pues no quiere sanos a los que tenga que alimentar.
      <br /><br/>      
      
      "Si tus vacunas no tienes, a ti te voy a llevar,<br/> 
      pues con sana distancia no basta (para variar).<br/> 
      Por <strong>${this.teacherName}</strong> he venido,
      <br/> 
      pues ya que ni por zoom he conocido"<br/> 
      <br /><br />
      No bajes la guardia, en estos días,<br/> 
      pues la Calaca te vigila, como feroz arpía,<br />
      Y con tus vacunas ponte al día.
      `;
      }
    },
  },
};
</script>
